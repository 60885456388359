import { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { format, set } from "date-fns";
import useGetSales, {
    ChannelStatsReportResultsV2,
    EModules,
    // IAPIChannelStatsReport,
    TChannelStatsReportOrderKeys,
    getSalesAsync,
} from "api/useGetSales";
import DateRange, { IDateRangeProps } from "components/DateRange";
import { PaginationState } from "components/Table";
import { ESortOrder, ISortState } from "components/Table/types";
import {
    IHeadObjectXLS,
    TActionProps,
    TGeneratePdfFunction,
    TGenerateXLSFunction,
} from "components/Table/components/ActionButtons/types";
import apiDateFormat from "constants/apiDateFormat";
import { website } from "constants/providerFilters";
import { useDebounce } from "hooks";
// import { EAPIProviderRanks, TProviders } from "types/APIProviderRanks";
import { IProviderCheckList } from "types/IProviderCheckList";
import { filterColumnsV2 } from "utils/filterColumns";
import {
    ELocalSavedFilters,
    ISavedDefaultFilters,
    commonTableFilters,
    getDefaultFilters,
    setDefaultFilters,
} from "utils/defaultFilters";
import formatOrderingAPI from "utils/formatOrderingAPI";
// import { ChannelStatsItem, IProvider } from "./types";
import useGetProviders, { EProvidersModules } from "api/useGetProviders";
import useUrlQuery from "hooks/useUrlQuery";
import { ChannelStatsUrlParams } from "utils/urlParams";
import { formattedDateRange, onGeneratePDFDefaultType } from "utils/helper";
import { useAppSelector } from "hooks/store";
import { selectUserData } from "store/auth/authSlice";
import { Box, Text } from "@chakra-ui/react";
import DataTable from "components/v2/DataTable";
import { ActionButtonV2 } from "components/Table/components/ActionButtons";
import { CustomizeProvider } from "components/v2/CustomizeProvider";
import commonDateRanges from "constants/commonDateRanges";
import { ColumnSizingState, SortingState, VisibilityState } from "@tanstack/react-table";
import { getColumns, getPdfColumns, getXLSColumns } from "./constants";

const channelStatsProviders = [website];

type TSavedFilters = ISavedDefaultFilters[ELocalSavedFilters.ChannelStatsTable];

const ChannelStats = () => {
    const userAuth = useAppSelector(selectUserData);

    const { getParam, getMultipleParams, setMultipleParams } =
        useUrlQuery<ChannelStatsUrlParams>();

    const startDateParams = getParam("startDate");
    const endDateParams = getParam("endDate");
    const sourceParams = getMultipleParams("source");
    const sortByParams = getParam("sortBy");
    const searchParams = getParam("search");

    const defaultFilters = getDefaultFilters<TSavedFilters>(
        ELocalSavedFilters.ChannelStatsTable,
        { ...commonTableFilters, providers: channelStatsProviders }
    );

    const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(defaultFilters.columnVisibility || {});

    const [columnSizing, setColumnSizing] = useState<ColumnSizingState>(defaultFilters.columnSizing || {});

    const [dateRange, setDateRange] = useState<IDateRangeProps["value"]>({
        startDate: startDateParams
            ? new Date(startDateParams)
            : defaultFilters.dateRange.startDate,
        endDate: endDateParams
            ? new Date(endDateParams)
            : defaultFilters.dateRange.endDate,
    });

    // const [offset, setOffset] = useState<number>(defaultOffset);
    const [page, setPage] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(defaultFilters.pageSize);
    const [providers, setProviders] = useState<IProviderCheckList[]>(
        defaultFilters.providers
    );

    // const [sortBy, setSortBy] = useState<ISortState>(
    //     sortByParams && typeof sortByParams === "string"
    //         ? {
    //             column: sortByParams.replace("-", ""),
    //             order: sortByParams.startsWith("-")
    //                 ? ESortOrder.Descending
    //                 : ESortOrder.Ascending,
    //         }
    //         : {
    //             column: null,
    //             order: ESortOrder.None,
    //         }
    // );

    const [sortBy, setSortBy] = useState<SortingState>(sortByParams && typeof sortByParams === 'string' ? [{
        id: sortByParams.replace("-", ""),
        desc: sortByParams.startsWith("-") ? true : false,
    }] : []);

    const [quickSearch, setQuickSearch] = useState<string>(searchParams ?? "");
    const quickSearchApi = useDebounce<string>(quickSearch, 350);

    useEffect(() => {
        const selectedProviders = providers
            .filter(({ value }) => value)
            .map(({ id }) => id);

        setMultipleParams({
            startDate: format(dateRange.startDate, apiDateFormat),
            endDate: format(dateRange.endDate, apiDateFormat),
            source: selectedProviders,
            inventory: userAuth.inventory.value,
            search: quickSearchApi,
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        dateRange.endDate,
        dateRange.startDate,
        userAuth.inventory.value,
        // sortBy.column,
        // sortBy.order,
        quickSearchApi,
    ]);

    // get 3rd party providers
    const fetchProvders = useMemo(
        () => ({
            module: EProvidersModules.ThirdPartyProviderV2,
            params: {
                dealership_uid: userAuth.dealerV2.uid,
                inventory: userAuth.inventory.value,
            },
        }),
        [userAuth.dealerV2.uid, userAuth.inventory.value]
    );

    const providerList =
        useGetProviders<EProvidersModules.ThirdPartyProviderV2>(fetchProvders);

    // set providers
    useMemo(() => {
        if (!providerList.isFetching && providerList.data) {
            const tempProviders: IProviderCheckList[] = (
                (providerList.data as any).results as any[]
            ).map((provider) => {
                return {
                    id: provider.value,
                    label: provider.label,
                    value: sourceParams?.includes(provider.value.toString()) || false,
                };
            });

            tempProviders.push(website);

            setProviders(tempProviders);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [providerList.data, providerList.isFetching]);

    const sortedBy: ISortState = useMemo(() => {

        if (!sortBy.length) {
            return {
                column: null,
                order: ESortOrder.None,
            };
        }
        return {
            column: sortBy[0].id as TChannelStatsReportOrderKeys,
            order: sortBy[0].desc ? ESortOrder.Descending : ESortOrder.Ascending,
        };
    }, [sortBy]);

    const fetchParameters = useMemo(
        () => ({
            module: EModules.ChannelStatsReportV2,
            params: {
                date_from: format(dateRange.startDate, apiDateFormat),
                date_to: format(dateRange.endDate, apiDateFormat),
                dealership_uid: userAuth.dealerV2.uid,
                page: page + 1,
                // limit: pageSize,
                page_size: pageSize,
                ordering: formatOrderingAPI<TChannelStatsReportOrderKeys>(sortedBy),
                search: quickSearchApi,
                source: providers.filter(({ value }) => value).map(({ id }) => id),
                inventory: userAuth.inventory.value,
            },
        }),
        [dateRange.startDate, dateRange.endDate, userAuth.dealerV2.uid, userAuth.inventory.value, page, pageSize, sortedBy, quickSearchApi, providers]
    );
    const { data, isFetching } =
        useGetSales<EModules.ChannelStatsReportV2>(fetchParameters);

    useEffect(() => {
        setDefaultFilters<TSavedFilters>(ELocalSavedFilters.ChannelStatsTable, {
            dateRange,
            pageSize,
            providers,
            columnSizing,
            columnVisibility
        });
    }, [columnSizing, columnVisibility, dateRange, pageSize, providers]);

    const onSort = useCallback((sort: SortingState) => {
        // if (!sort.length) return;
        setSortBy(sort);
        setMultipleParams({
          sortBy: sort.length > 0 ? `${sort[0].desc ? '-' : ''}${sort[0].id}` : '',
        });
    }, []);

    const filteredColumns = useMemo(
        () =>
            filterColumnsV2(
                getColumns(userAuth.inventory.value),
                providers
            ),
        [providers, userAuth.inventory.value]
    );

    const onGenerateXLSX: TActionProps<TGenerateXLSFunction> = useCallback(
        async (generateXLS, setIsLoading) => {
            setIsLoading(true);

            try {
                // let hasNextPage = true;
                let collectedResults: ChannelStatsReportResultsV2[] = [];
                // let mapOffset = defaultOffset;

                let hasNextPage = true;

                let tempPage = 1;

                do {
                    const { next, results } =
                        await getSalesAsync<EModules.ChannelStatsReportV2>({
                            ...fetchParameters,
                            params: {
                                ...fetchParameters.params,
                                page: tempPage,
                                // offset: mapOffset,
                            },
                        })();

                    collectedResults = [...collectedResults, ...results];

                    tempPage++;
                    // console.log('next', next);

                    hasNextPage = next ? true : false;
                    // mapOffset = mapOffset + defaultPageSize;
                } while (hasNextPage);

                let groupedHeadFormatted: [IHeadObjectXLS[], string[]] = [[], []];

                // filter providers
                const filteredProviders = providers.filter(({ value }) => value);

                // filter columns
                const filteredXlsColumns = getXLSColumns(userAuth.inventory.value).map((column) => {
                        if (column.columns) {
                            column.columns = column.columns.filter(
                                (groupedColumn) =>
                                    !groupedColumn.filterColumnId ||
                                    filteredProviders.some(
                                        (provider) => provider.id === groupedColumn.filterColumnId
                                    )
                            );
                        }
                        return column;
                    })

                    .filter(
                        (column) =>
                            !column.filterColumnId ||
                            filteredProviders.some(
                                (provider) => provider.id === column.filterColumnId
                            )
                    );

                // rest of the code remains the same
                const bodyFormatted = collectedResults.map((values) => {
                    const groupedColumns = filteredXlsColumns
                        // .filter(({ filterColumnId: columnFilterId }) => columnFilterId === filterColumnId)
                        .map(({ columns: groupColumns, func, key, filterColumnId }) => {
                            if (!groupColumns) {
                                return (
                                    (func?.(values[key], values) as string) ||
                                    (values[key] as string)
                                );
                            } else {
                                return groupColumns.map(({ func, key }) => {
                                    // console.log('func', values);
                                    return (
                                        (func?.(values[key], values) as string) ||
                                        (values[key] as string)
                                    );
                                });
                            }
                        });

                    return groupedColumns.flat();
                });

                for (
                    let indexHeadColumn = 0;
                    indexHeadColumn < filteredXlsColumns.length;
                    indexHeadColumn++
                ) {
                    const {
                        colspan,
                        label,
                        columns: groupedColumns,
                    } = filteredXlsColumns[indexHeadColumn];

                    groupedHeadFormatted[0].push({ colspan, label });

                    if (groupedColumns && groupedColumns.length) {
                        groupedColumns.map(({ label }) =>
                            groupedHeadFormatted[1].push(label)
                        );
                    }
                }

                generateXLS(
                    {
                        head: groupedHeadFormatted,
                        body: bodyFormatted,
                    },
                    "channel-stats-report.xls"
                );
                setIsLoading(false);
            } catch (err) {
                console.log("err", err);
                toast.error("Can't export XLS file. Please contact your admin");
            }
        },
        [fetchParameters, providers, userAuth.inventory.value]
    );

    const onGeneratePDF: TActionProps<TGeneratePdfFunction> = useCallback(
        async (onGeneratePDF, setIsLoading, provider) => {
            setIsLoading(true);

            console.log("provider", provider);

            try {
                // let hasNextPage = true;
                let collectedResults: ChannelStatsReportResultsV2[] = [];
                // let mapOffset = defaultOffset;

                let hasNextPage = true;

                let tempPage = 1;

                do {
                    const { next, results } =
                        await getSalesAsync<EModules.ChannelStatsReportV2>({
                            ...fetchParameters,
                            params: {
                                ...fetchParameters.params,
                                page: tempPage,
                                // offset: mapOffset,
                            },
                        })();

                    collectedResults = [...collectedResults, ...results];

                    tempPage++;
                    // console.log('next', next);

                    hasNextPage = next ? true : false;
                    // mapOffset = mapOffset + defaultPageSize;
                } while (hasNextPage);

                const filteredPDFColumns = getPdfColumns(userAuth.inventory.value).filter(
                    ({ filterColumnId }) =>
                        !filterColumnId || filterColumnId === provider?.id
                );

                const bodyFormatted = collectedResults.map((values) => {
                    return filteredPDFColumns.map(
                        ({ func, key }) =>
                            func?.(values[key], values) || (values[key] as string)
                    );
                });

                const dateRangeTitle = formattedDateRange(dateRange);

                onGeneratePDF(
                    {
                        head: [filteredPDFColumns.map(({ label }) => label)],
                        body: bodyFormatted,
                        ...onGeneratePDFDefaultType(
                            provider
                                ? `Channel Stats Report - ${provider.label}`
                                : `Channel Stats Report`,
                            dateRangeTitle
                        ),
                    },
                    provider
                        ? `channel-stats-report-${provider.label}.pdf`
                        : "channel-stats-report.pdf",
                    {
                        orientation: "landscape",
                        compress: true,
                    }
                );
                setIsLoading(false);
            } catch (err) {
                toast.error("Can't export PDF file. Please contact your admin");
            }
        },
        [dateRange, fetchParameters, userAuth.inventory.value]
    );

    const onProviderChange = (selectedProvider: IProviderCheckList) => {
        setProviders((prevProviders) =>
            prevProviders.map((provider) =>
                provider.id === selectedProvider.id
                    ? { ...provider, value: !provider.value }
                    : provider
            )
        );
    };

    const onDateChange = (newValue: [Date, Date]) => {
        // setDateRange({ startDate, endDate });
        setDateRange({
            startDate: set(newValue[0], { hours: 0, minutes: 0, seconds: 0 }),
            endDate: set(newValue[1], { hours: 23, minutes: 59, seconds: 59 }),
        });
    };

    const onPageChange = useCallback((pagination: PaginationState) => {
        setPage(pagination.pageIndex);
        setPageSize(pagination.pageSize);
    }, []);

    const columnSidebarChilrend = () => {
        return (
            <Box display="flex" flexFlow="column" gap="10px">
                <Text fontWeight="600">Providers</Text>
                <CustomizeProvider providers={providers} onChange={onProviderChange} />
            </Box>
        );
    };

    const conttroller = () => {
        return (
            <Box
                display="flex"
                gap="15px"
                justifyContent="space-between"
                flex="1"
                alignItems="center"
            >
                <Box display="flex" alignItems="center" gap="15px" flex="1" />
                <Box>
                    <ActionButtonV2
                        actionConfig={{
                            isDownloadXLS: true,
                            isProviderPrint: true,
                            isPrintPDF: true,
                            isEmailPDF: true,
                            isEmailXLS: true,
                            isDownloadPDF: true,
                        }}
                        providers={providers}
                        onDownloadPDF={onGeneratePDF}
                        onDownloadXLS={onGenerateXLSX}
                        onGeneratePDF={onGeneratePDF}
                    />
                </Box>
            </Box>
        );
    };

    return (
        <>
            <Box display="flex" flexDirection="column" gap="15px">
                <DateRange
                    onChange={onDateChange}
                    quickOptions={commonDateRanges}
                    value={dateRange}
                />
                <DataTable<ChannelStatsReportResultsV2>
                    data={data?.results || []}
                    rowCount={data?.count || 0}
                    onPageChange={onPageChange}
                    sort={sortBy}
                    onSort={onSort}
                    columns={filteredColumns as any}
                    pageIndex={page}
                    pageSize={pageSize}
                    isLoading={isFetching}
                    conttroller={conttroller()}
                    onSearch={setQuickSearch}
                    pageCount={data?.total_pages}
                    onColumnVisibilityChange={setColumnVisibility}
                    columnVisibility={columnVisibility}
                    onColumnSizingChange={setColumnSizing}
                    columnSizing={columnSizing}
                    showColumns={true}
                    // onUpdateData={updateData}
                    columnsSidebarChildren={columnSidebarChilrend()}
                    triggerButtonType="text"
                />
            </Box>
        </>
    );
};
export default ChannelStats;
