import { createColumnHelper } from "@tanstack/react-table";
import { Dispatch, SetStateAction } from "react";
import { TBudgetROIDataV2 } from "api/useGetSales";
import { ReactComponent as EditIcon } from "assets/icons/edit-03.svg";
import Button from "components/Button";
import { EProviderInitials } from "types/APIProviderRanks";
import TAggregatorColumnPDF from "types/TAggregatorColumnPDF";
import getProviderName from "utils/getProviderName";
import styles from "../../../Marketing/components/Tables/components/BudgetROI/styles.module.scss";
import { TInventoryOptionValue } from "constants/common";
import { capitalizeFirstLetter } from "utils/helper";
import TAggregatorColumnXLS from "types/TAggregatorColumnXLS";
import { IEditBudgetState } from "pages/Marketing/components/Tables/components/BudgetROI/types";

const columnHelper = createColumnHelper<TBudgetROIDataV2>();

export const getColumns = (
  setEditBudget: Dispatch<SetStateAction<IEditBudgetState>>,
  inventory: TInventoryOptionValue
) => [
  columnHelper.accessor((row) => row, {
    id: "rank",
    cell: (info) => info.row.index + 1,
    header: `Rank: ${capitalizeFirstLetter(inventory)}`,
    size: 200,
    enableSorting: true
  }),
  columnHelper.accessor((row) => row.source, {
    id: "source",
    cell: (info) => getProviderName(info.getValue()),
    header: `Source: ${capitalizeFirstLetter(inventory)}`,
    size: 200,
    enableSorting: true
  }),
  columnHelper.accessor((row) => row.budget, {
    id: "budget",
    cell: ({ getValue, row: { original } }) => inventory !== 'all' ? (
      <div className={styles.budgetColumn}>
        <Button
          className={styles["budgetColumn__editBudget"]}
          variant="text"
          onClick={() =>
            setEditBudget({
              isOpen: true,
              provider: original.source,
              value: original.monthly_budget || 0, 
            })
          }
        >
          <EditIcon />
        </Button>
        {(getValue() || 0).toLocaleString()} 
      </div>
    ) : (getValue() || 0).toLocaleString(),
    header: `Budget: ${capitalizeFirstLetter(inventory)}`,
    size: 200,
    enableSorting: true
  }),
  columnHelper.accessor((row) => row.total_vdp_count, {
    id: "total_vdp_count",
    cell: (info) => (info.getValue() || 0).toLocaleString(), 
    header: `Total VDPs: ${capitalizeFirstLetter(inventory)}`,
    size: 200,
    enableSorting: true
  }),
  columnHelper.accessor((row) => row.cost_vdps, {
    id: "cost_vdps",
    cell: (info) => `$${(info.getValue() || 0).toLocaleString()}`, 
    header: `Cost / VDP: ${capitalizeFirstLetter(inventory)}`,
    size: 200,
    enableSorting: true
  }),
  columnHelper.accessor((row) => row.total_lead_count, {
    id: "total_lead_count",
    cell: (info) => `${(info.getValue() || 0).toLocaleString()}`, 
    header: `Total Leads: ${capitalizeFirstLetter(inventory)}`,
    size: 200,
    enableSorting: true
  }),
  columnHelper.accessor((row) => row.cost_leads, {
    id: "cost_leads",
    cell: (info) => `$${(info.getValue() || 0).toLocaleString()}`, 
    header: `Cost / Lead: ${capitalizeFirstLetter(inventory)}`,
    size: 200,
    enableSorting: true
  }),
  columnHelper.accessor((row) => row.total_sold_count, {
    id: "total_sold_count",
    cell: (info) => (info.getValue() || 0).toLocaleString(),
    header: `Sold: ${capitalizeFirstLetter(inventory)}`,
    size: 200,
    enableSorting: true
  }),
  columnHelper.accessor((row) => row.cost_sale, {
    id: "cost_sale",
    cell: (info) => `$${(info.getValue() || 0).toLocaleString()}`,
    header: `Cost / Sale: ${capitalizeFirstLetter(inventory)}`,
    size: 200,
    enableSorting: true
  }),
  columnHelper.accessor((row) => row.roi_rank, {
    id: "roi_rank",
    cell: (info) => info.getValue() ? `${(info.getValue() || 0).toLocaleString()}` : 'N/A',
    header: `ROI Rank: ${capitalizeFirstLetter(inventory)}`,
    size: 200,
    enableSorting: true
  }),
  // columnHelper.accessor((row) => row.leads_vdps, {
  //   id: "leadpervdp",
  //   cell: (info) => `${(info.getValue() || 0).toLocaleString()}%`,
  //   header: () => (
  //     <span>
  //       Lead / VDP: 
  //       <span style={{ color: 'gray', paddingLeft: '3px' }}>{capitalizeFirstLetter(inventory)}</span>
  //     </span>
  //   ),
  // }),
];

export const getPdfColumns = (inventory: TInventoryOptionValue): TAggregatorColumnPDF<TBudgetROIDataV2>[] => ([
  {
    // label: "Rank",
    label: `Rank: ${capitalizeFirstLetter(inventory)}`,
    key: "rank",
  },
  {
    // label: "Source",
    label: `Source: ${capitalizeFirstLetter(inventory)}`,
    key: "source",
    func: (value) => getProviderName(value as EProviderInitials),
  },
  {
    // label: "Budget",
    label: `Budget: ${capitalizeFirstLetter(inventory)}`,
    key: "budget", 
    func: (value) => (value || 0).toLocaleString(),
  },
  {
    // label: "Total VDPs",
    label: `Total VDPs: ${capitalizeFirstLetter(inventory)}`,
    key: "total_vdp_count",
    func: (value) => (value || 0).toLocaleString(),
  },
  {
    // label: "Cost / VDP",
    label: `Cost / VDP: ${capitalizeFirstLetter(inventory)}`,
    key: "cost_vdps", 
    func: (value) => `$${(value || 0).toLocaleString()}`,
  },
  {
    label: `Total Leads: ${capitalizeFirstLetter(inventory)}`,
    key: "total_lead_count",
    func: (value) => `${(value || 0).toLocaleString()}`,
  },
  {
    // label: "Cost / Lead", 
    label: `Cost / Lead: ${capitalizeFirstLetter(inventory)}`,
    key: "cost_leads",
    func: (value) => `$${(value || 0).toLocaleString()}`,
  },
  {
    // label: "Sold",
    label: `Sold: ${capitalizeFirstLetter(inventory)}`,
    key: "total_sold_count",
    func: (value) => (value || 0).toLocaleString(),
  },
  {
    // label: "Cost / Sale", 
    label: `Cost / Sale: ${capitalizeFirstLetter(inventory)}`,
    key: "cost_sale", 
    func: (value) => `$${(value || 0).toLocaleString()}`,
  },
  {
    // label: "ROI Rank",
    label: `ROI Rank: ${capitalizeFirstLetter(inventory)}`,
    key: "roi_rank",
    func: (value) => value ? `${(value || 0).toLocaleString()}` : 'N/A',
  }
  // {
  //   // label: "Lead / VDP",
  //   label: `Lead / VDP: ${capitalizeFirstLetter(inventory)}`,
  //   key: "leads_vdps", 
  //   func: (value) => `${(value || 0).toLocaleString()}%`,
  // },
]);

export const getXLSColumns = (inventory: TInventoryOptionValue): TAggregatorColumnXLS<TBudgetROIDataV2>[] => ([
  {
    // label: "Rank",
    label: `Rank: ${capitalizeFirstLetter(inventory)}`,
    key: "rank", 
  },
  {
    // label: "Source",
    label: `Source: ${capitalizeFirstLetter(inventory)}`,
    key: "source", 
    func: (value) => getProviderName(value as EProviderInitials),
  },
  {
    // label: "Budget",
    label: `Budget: ${capitalizeFirstLetter(inventory)}`,
    key: "budget", 
    func: (value) => (value || 0).toLocaleString(),
  },
  {
    // label: "Total VDPs",
    label: `Total VDPs: ${capitalizeFirstLetter(inventory)}`,
    key: "total_vdp_count", 
    func: (value) => (value || 0).toLocaleString(),
  },
  {
    // label: "Cost / VDP", 
    label: `Cost / VDP: ${capitalizeFirstLetter(inventory)}`,
    key: "cost_vdps", 
    func: (value) => `$${(value || 0).toLocaleString()}`,
  },
  {
    label: `Total Leads: ${capitalizeFirstLetter(inventory)}`,
    key: "total_lead_count",
    func: (value) => `${(value || 0).toLocaleString()}`,
  },
  {
    // label: "Cost / Lead", 
    label: `Cost / Lead: ${capitalizeFirstLetter(inventory)}`,
    key: "cost_leads", 
    func: (value) => `$${(value || 0).toLocaleString()}`, 
  },
  {
    // label: "Sold",
    label: `Sold: ${capitalizeFirstLetter(inventory)}`, 
    key: "total_sold_count",  
    func: (value) => (value || 0).toLocaleString(), 
  },
  {
    // label: "Cost / Sale",
    label: `Cost / Sale: ${capitalizeFirstLetter(inventory)}`, 
    key: "cost_sale",
    func: (value) => `$${(value || 0).toLocaleString()}`, 
  },
  {
    // label: "ROI Rank",
    label: `ROI Rank: ${capitalizeFirstLetter(inventory)}`, 
    key: "roi_rank", 
    func: (value) => value ? `${(value || 0).toLocaleString()}` : 'N/A',
  }
  // {
  //   // label: "Lead / VDP",
  //   label: `Lead / VDP: ${capitalizeFirstLetter(inventory)}`, 
  //   key: "leads_vdps", 
  //   func: (value) => `${(value || 0).toLocaleString()}%`, 
  // },
]);
