import { createColumnHelper } from "@tanstack/react-table";
import { ChannelStatsReportResultsV2 } from "api/useGetSales";
import Badge from "components/Badge";
import SeeMoreText from "components/SeeMoreTexts/SeeMoreTexts";
import { TInventoryOptionValue } from "constants/common";
import { EAPIProviderRanks, EAPIProviderRanksReverse, EProviderInitials } from "types/APIProviderRanks";
import TAggregatorColumnPDF from "types/TAggregatorColumnPDF";
import TAggregatorColumnXLS from "types/TAggregatorColumnXLS";
import getProviderRank from "utils/getProviderRank";

const columnHelper = createColumnHelper<ChannelStatsReportResultsV2>();

export const getColumns = (inventory: TInventoryOptionValue) => [
    columnHelper.group({
        header: "Vehicle Info",
        id: "vehicleInfo",
        columns: [
            columnHelper.accessor("stock_number", {
                cell: (info) => info.getValue(),
                header: "Stock #",
                size: 200,
            }),
            columnHelper.accessor("vehicle", {
                id: "vehicle",
                cell: (info) => info.getValue(),
                header: "Vehicle",
                size: 350,
            }),
            columnHelper.accessor("description", {
                cell: (info) => info.getValue() ? (
                    <SeeMoreText maxLength={52} text={info.getValue()} />
                ) : undefined,
                enableSorting: false,
                header: "Description",
                size: 400,
                enableResizing: true
            }),
            columnHelper.accessor("dol", {
                id: "dol",
                cell: (info) => info.getValue(),
                header: "DOL",
                maxSize: 70,
            }),
            columnHelper.accessor("photos_count", {
                id: "photos_count",
                cell: (info) => info.getValue(),
                header: "Photos",
                maxSize: 70,
            }),
        ],
    }),
    {
        header: "Autotrader",
        filterColumnId: EProviderInitials.Autotrader,
        columns: [
            columnHelper.accessor("atc_price", {
                cell: (info) => info.getValue(),
                header: "Price",
                id: `atc_price`,
            }),
            ...(inventory === "used" ? [
                columnHelper.accessor("atc_deal_rating", {
                    cell: (info) => {
                        const { color, icon, label } = getProviderRank(
                            info.getValue() as EAPIProviderRanks
                        );
                        return <Badge color={color} icon={icon} label={label} size="sm" />;
                    },
                    header: "Deal Rating",
                    id: `atc_deal_rating`,
                    size: 250,
                }),
            ] : []),
            columnHelper.accessor("atc_total_srps", {
                cell: (info) => info.getValue(),
                header: "SRP",
                id: `atc_total_srps`,
            }),
            columnHelper.accessor("atc_total_vdps", {
                cell: (info) => info.getValue(),
                header: "VDP",
                id: `atc_total_vdps`,
            }),
            columnHelper.accessor("atc_srps_vdps", {
                cell: (info) => info.getValue(),
                header: "SRP/VDP Ratio",
                id: `atc_srps_vdps`,
                size: 350,
            }),
        ],
    },
    {
        header: "CarGurus",
        filterColumnId: EProviderInitials.CarGurus,
        // columns: getProviderColumns("cargurus", "cargurus"),
        columns: [
            columnHelper.accessor("cgr_price", {
                cell: (info) => info.getValue(),
                header: () => "Price",
                id: `cgr_price`,
            }),
            ...(inventory === "used" ? [
                columnHelper.accessor("cgr_deal_rating", {
                    cell: (info) => {
                        const { color, icon, label } = getProviderRank(
                            info.getValue() as EAPIProviderRanks
                        );
                        return <Badge color={color} icon={icon} label={label} size="sm" />;
                    },
                    header: "Deal Rating",
                    id: `cgr_deal_rating`,
                    size: 250,
                }),
                // columnHelper.accessor("cgr_total_srps", {
                //     cell: (info) => info.getValue(),
                //     header: "SRP",
                //     id: `cgr_total_srps`,
                // }),
            ] : []),
            columnHelper.accessor("cgr_total_vdps", {
                cell: (info) => info.getValue(),
                header: "VDP",
                id: `cgr_total_vdps`,
            }),
        ],
    },
    {
        header: "Cars.com",
        filterColumnId: EProviderInitials.CarsDotCom,
        // columns: getProviderColumns("carsDotCom", "cars_com"),
        columns: [
            columnHelper.accessor("ccm_price", {
                cell: (info) => info.getValue(),
                header: "Price",
                id: `ccm_price`,
            }),
            ...(inventory === "used" ? [
                columnHelper.accessor("ccm_deal_rating", {
                    cell: (info) => {
                        const { color, icon, label } = getProviderRank(
                            info.getValue() as EAPIProviderRanks
                        );
                        return <Badge color={color} icon={icon} label={label} size="sm" />;
                    },
                    header: "Deal Rating",
                    id: `ccm_deal_rating`,
                    size: 250,
                }),
            ] : []),
            columnHelper.accessor("ccm_total_srps", {
                cell: (info) => info.getValue(),
                header: "SRP",
                id: `ccm_total_srps`,
            }),
            columnHelper.accessor("ccm_total_vdps", {
                cell: (info) => info.getValue(),
                header: "VDP",
                id: `ccm_total_vdps`,
            }),
            columnHelper.accessor("ccm_srps_vdps", {
                cell: (info) => info.getValue(),
                header: "SRP/VDP Ratio",
                id: `ccm_srps_vdps`,
                size: 350,
            }),
        ],
    },
    // Temporarily hides carfax column for demo purposes
    {
        header: "Carfax",
        id: "carfax",
        filterColumnId: EProviderInitials.Carfax,
        columns: [
            columnHelper.accessor("cfx_price", {
                cell: (info) => info.getValue(),
                header: "Price",
                id: `cfx_price`,
            }),
            ...(inventory === "used" ? [
                columnHelper.accessor("cfx_deal_rating", {
                    cell: (info) => {
                        const { color, icon, label } = getProviderRank(
                            info.getValue() as EAPIProviderRanks
                        );
                        return <Badge color={color} icon={icon} label={label} size="sm" />;
                    },
                    header: "Deal Rating",
                    id: `cfx_deal_rating`,
                    size: 250,
                }),
                columnHelper.accessor("cfx_total_srps", {
                    cell: (info) => info.getValue(),
                    header: "SRP",
                    id: `cfx_total_srps`,
                }),
            ] : []),
            columnHelper.accessor("cfx_total_vdps", {
                cell: (info) => info.getValue(),
                header: "VDP",
                id: `ccm_total_vdps`,
            }),
            ...(inventory === "used" ? [
                columnHelper.accessor("cfx_srps_vdps", {
                    cell: (info) => info.getValue(),
                    header: "SRP/VDP Ratio",
                    id: `cfx_srps_vdps`,
                    size: 350,
                }),
            ] : []),
        ],
    },
    {
        header: "Website",
        filterColumnId: EProviderInitials.GoogleAnalytics,
        // columns: getProviderColumns("website", "website"),
        columns: [
            columnHelper.accessor("ga_total_vdps", {
                cell: (info) => info.getValue(),
                header: "VDP",
                id: `ga_total_vdps`,
            }),
        ],
    },
];

export const getXLSColumns = (
    inventory: TInventoryOptionValue
): TAggregatorColumnXLS<ChannelStatsReportResultsV2>[] => [
        {
            label: "Vehicle",
            key: "description",
            colspan: 5,
            columns: [
                { label: "Stock #", key: "stock_number" },
                { label: "Vehicle", key: "vehicle" },
                { label: "Description", key: "description" },
                { label: "DOL", key: "dol" },
                { label: "Photos", key: "photos_count" },
            ],
        },
        {
            label: "Autotrader",
            key: "description",
            filterColumnId: EProviderInitials.Autotrader,
            colspan: 5,
            columns: [
                {
                    label: "Price",
                    key: "atc_price" as keyof ChannelStatsReportResultsV2,
                },
                ...(inventory === "used" ? [
                    {
                        label: "Deal Rating",
                        key: "atc_deal_rating" as keyof ChannelStatsReportResultsV2,
                        func(value, row) {
                            return EAPIProviderRanksReverse[value as EAPIProviderRanks];
                        },
                    },
                ] : []) as TAggregatorColumnXLS<ChannelStatsReportResultsV2>[],
                {
                    label: "SRP",
                    key: "atc_total_srps" as keyof ChannelStatsReportResultsV2,
                },
                {
                    label: "VDP",
                    key: "atc_total_vdps" as keyof ChannelStatsReportResultsV2,
                },
                {
                    label: "SRP/VDP Ratio",
                    key: "atc_srps_vdps" as keyof ChannelStatsReportResultsV2,
                }
            ],
        },
        {
            label: "CarGurus",
            key: "description",
            filterColumnId: EProviderInitials.CarGurus,
            colspan: 3,
            columns: [
                {
                    label: "Price",
                    key: "cgr_price" as keyof ChannelStatsReportResultsV2,
                },
                ...(inventory === "used" ? [
                    {
                        label: "Deal Rating",
                        key: "cgr_deal_rating" as keyof ChannelStatsReportResultsV2,
                        func(value, row) {
                            return EAPIProviderRanksReverse[value as EAPIProviderRanks];
                        },
                    },
                    // {
                    //   label: "SRP",
                    //   key: "cgr_total_srps" as keyof ChannelStatsReportResultsV2,
                    // },
                ] : []) as TAggregatorColumnXLS<ChannelStatsReportResultsV2>[],
                {
                    label: "VDP",
                    key: "cgr_total_vdps" as keyof ChannelStatsReportResultsV2,
                },
                // {
                //   label: "SRP/VDP Ratio",
                //   key: "cgr_srps_vdps" as keyof ChannelStatsReportResultsV2,
                // }
            ],
        },
        {
            label: "Carfax",
            key: "description",
            filterColumnId: EProviderInitials.Carfax,
            colspan: 5,
            columns: [
                {
                    label: "Price",
                    key: "cfx_price" as keyof ChannelStatsReportResultsV2,
                },
                ...(inventory === "used" ? [
                    {
                        label: "Deal Rating",
                        key: "cfx_deal_rating" as keyof ChannelStatsReportResultsV2,
                        func(value, row) {
                            return EAPIProviderRanksReverse[value as EAPIProviderRanks];
                        },
                    },
                    {
                        label: "SRP",
                        key: "cfx_total_srps" as keyof ChannelStatsReportResultsV2,
                    },
                ] : []) as TAggregatorColumnXLS<ChannelStatsReportResultsV2>[],
                {
                    label: "VDP",
                    key: "cfx_total_vdps" as keyof ChannelStatsReportResultsV2,
                },
                ...(inventory === "used" ? [
                    {
                        label: "SRP/VDP Ratio",
                        key: "cfx_srps_vdps" as keyof ChannelStatsReportResultsV2,
                    }
                ] : []) as TAggregatorColumnXLS<ChannelStatsReportResultsV2>[],
            ],
        },
        {
            label: "Cars.com",
            key: "description",
            filterColumnId: EProviderInitials.CarsDotCom,
            colspan: 5,
            columns: [
                {
                    label: "Price",
                    key: "ccm_price" as keyof ChannelStatsReportResultsV2,
                },
                ...(inventory === "used" ? [
                    {
                        label: "Deal Rating",
                        key: "ccm_deal_rating" as keyof ChannelStatsReportResultsV2,
                        func(value, row) {
                            return EAPIProviderRanksReverse[value as EAPIProviderRanks];
                        },
                    },
                ] : []) as TAggregatorColumnXLS<ChannelStatsReportResultsV2>[],
                {
                    label: "SRP",
                    key: "ccm_total_srps" as keyof ChannelStatsReportResultsV2,
                },
                {
                    label: "VDP",
                    key: "ccm_total_vdps" as keyof ChannelStatsReportResultsV2,
                },
                {
                    label: "SRP/VDP Ratio",
                    key: "ccm_srps_vdps" as keyof ChannelStatsReportResultsV2,
                }
            ],
        },
        {
            label: "Website",
            key: "description",
            filterColumnId: EProviderInitials.GoogleAnalytics,
            colspan: 1,
            columns: [
                // {
                //   label: "Price",
                //   key: "ga_price" as keyof ChannelStatsReportResultsV2,
                // },
                // {
                //   label: "Deal Rating",
                //   key: "ga_deal_rating" as keyof ChannelStatsReportResultsV2,
                // },
                // {
                //   label: "SRP",
                //   key: "ga_total_srps" as keyof ChannelStatsReportResultsV2,
                // },
                {
                    label: "VDP",
                    key: "ga_total_vdps" as keyof ChannelStatsReportResultsV2,
                },
                // {
                //   label: "SRP/VDP Ratio",
                //   key: "ga_srps_vdps" as keyof ChannelStatsReportResultsV2,
                // }
            ],
        },
    ];

export const getPdfColumns = (
    inventory: TInventoryOptionValue
): TAggregatorColumnPDF<ChannelStatsReportResultsV2>[] => [
        {
            key: 'stock_number',
            label: 'Stock #',
        },
        {
            key: 'vehicle',
            label: 'Vehicle',
        },
        // {
        //   key: 'description',
        //   label: 'Description',
        // },
        {
            key: 'dol',
            label: 'DOL',
        },
        {
            key: 'photos_count',
            label: 'Photos',
        },
        {
            key: 'atc_price',
            label: 'Price',
            filterColumnId: EProviderInitials.Autotrader,
        },
        ...(inventory === "used" ? [
            {
                key: 'atc_deal_rating',
                label: 'Deal Rating',
                filterColumnId: EProviderInitials.Autotrader,
                func(value) {
                    return EAPIProviderRanksReverse[value as EAPIProviderRanks];
                }
            },
            {
                key: 'atc_total_srps',
                label: 'SRP',
                filterColumnId: EProviderInitials.Autotrader,
            },
        ] : []) as TAggregatorColumnPDF<ChannelStatsReportResultsV2>[],
        {
            key: 'atc_total_vdps',
            label: 'VDP',
            filterColumnId: EProviderInitials.Autotrader,
        },
        ...(inventory === "used" ? [
            {
                key: 'atc_srps_vdps',
                label: 'SRP/VDP Ratio',
                filterColumnId: EProviderInitials.Autotrader,
            },
        ] : []) as TAggregatorColumnPDF<ChannelStatsReportResultsV2>[],
        {
            key: 'cgr_price',
            label: 'Price',
            filterColumnId: EProviderInitials.CarGurus,
        },
        ...(inventory === "used" ? [
            {
                key: 'cgr_deal_rating',
                label: 'Deal Rating',
                filterColumnId: EProviderInitials.CarGurus,
                func(value) {
                    return EAPIProviderRanksReverse[value as EAPIProviderRanks];
                }
            },
        ] : []) as TAggregatorColumnPDF<ChannelStatsReportResultsV2>[],
        // {
        //   key: 'cgr_total_srps',
        //   label: 'SRP',
        //   filterColumnId: EProviderInitials.CarGurus,
        // },
        {
            key: 'cgr_total_vdps',
            label: 'VDP',
            filterColumnId: EProviderInitials.CarGurus,
        },
        // {
        //   key: 'cgr_srps_vdps',
        //   label: 'SRP/VDP Ratio',
        //   filterColumnId: EProviderInitials.CarGurus,
        // },
        {
            key: 'cfx_price',
            label: 'Price',
            filterColumnId: EProviderInitials.Carfax,
        },
        ...(inventory === "used" ? [
            {
                key: 'cfx_deal_rating',
                label: 'Deal Rating',
                filterColumnId: EProviderInitials.Carfax,
                func(value) {
                    return EAPIProviderRanksReverse[value as EAPIProviderRanks];
                }
            },
            {
                key: 'cfx_total_srps',
                label: 'SRP',
                filterColumnId: EProviderInitials.Carfax,
            },
        ] : []) as TAggregatorColumnPDF<ChannelStatsReportResultsV2>[],
        {
            key: 'cfx_total_vdps',
            label: 'VDP',
            filterColumnId: EProviderInitials.Carfax,
        },
        ...(inventory === "used" ? [
            {
                key: 'cfx_srps_vdps',
                label: 'SRP/VDP Ratio',
                filterColumnId: EProviderInitials.Carfax,
            },
        ] : []) as TAggregatorColumnPDF<ChannelStatsReportResultsV2>[],
        {
            key: 'ccm_price',
            label: 'Price',
            filterColumnId: EProviderInitials.CarsDotCom,
        },
        ...(inventory === "used" ? [
            {
                key: 'ccm_deal_rating',
                label: 'Deal Rating',
                filterColumnId: EProviderInitials.CarsDotCom,
                func(value) {
                    return EAPIProviderRanksReverse[value as EAPIProviderRanks];
                }
            },
            {
                key: 'ccm_total_srps',
                label: 'SRP',
                filterColumnId: EProviderInitials.CarsDotCom,
            },
        ] : []) as TAggregatorColumnPDF<ChannelStatsReportResultsV2>[],
        {
            key: 'ccm_total_vdps',
            label: 'VDP',
            filterColumnId: EProviderInitials.CarsDotCom,
        },
        ...(inventory === "used" ? [
            {
                key: 'ccm_srps_vdps',
                label: 'SRP/VDP Ratio',
                filterColumnId: EProviderInitials.CarsDotCom,
            },
        ] : []) as TAggregatorColumnPDF<ChannelStatsReportResultsV2>[],
        {
            key: 'ga_total_vdps',
            label: 'VDP',
            filterColumnId: EProviderInitials.GoogleAnalytics,
        }
    ];
